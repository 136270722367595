import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// consts
export const CUSTOMERS_RESOURCE = "customers";

// action types
export const CUSTOMERS_SEARCH = "customersSearch";

// mutation types
export const SET_CUSTOMERS = "setCustomers";
export const SET_ERROR = "setError";

export const PURGE_CUSTOMERS = "purgeCustomers";

const state = {
  errors: null,
  customer: null,
  customers: []
};

const getters = {
  customers(state) {
    return state.customers;
  },
  customer(state) {
    return state.customer;
  }
};

const actions = {
  [CUSTOMERS_SEARCH](context, requestPayload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      // console.log(requestPayload);
      // console.log(queryParams);
      return ApiService.get(CUSTOMERS_RESOURCE, "", requestPayload)
        .then(({ data }) => {
          context.commit(SET_CUSTOMERS, data.payload.data);
          return data;
        })
        .catch(({ response }) => {
          // context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_ERROR, response);
        });
    }
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_CUSTOMERS](state, customers) {
    state.customers = customers;
  },
  [PURGE_CUSTOMERS](state) {
    state.customers = [];
    state.errors = {};
    JwtService.destroyToken();
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
