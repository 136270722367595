import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import breadcrumbs from "./breadcrumbs.module";
import profile from "./profile.module";
import user from "./user.module";
import renewal from "./renewal.module";
import insurer from "./insurer.module";
import customer from "./customer.module";
import mock from "./mock.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    breadcrumbs,
    profile,
    user,
    renewal,
    insurer,
    customer,
    mock
  }
});
