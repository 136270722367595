import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/dashboard/PageView.vue")
        },
        {
          path: "/settings",
          name: "settings",
          component: () => import("@/view/pages/settings/SettingsPageView.vue")
        },
        {
          path: "/settings/user",
          name: "settings-user",
          component: () => import("@/view/pages/settings/user/PageView.vue")
        },
        {
          path: "/settings/product",
          name: "settings-product",
          component: () => import("@/view/pages/settings/product/PageView.vue")
        },
        {
          path: "/settings/insurer",
          name: "settings-insurer",
          component: () => import("@/view/pages/settings/insurer/PageView.vue")
        },
        {
          path: "/calculator/auto",
          name: "calculator-auto",
          component: () => import("@/view/pages/calculator/car/PageView.vue")
        },
        {
          path: "/calculator/property",
          name: "calculator-property",
          component: () => import("@/view/pages/calculator/property/PageView.vue")
        },
        {
          path: "/calculator/life",
          name: "calculator-life",
          component: () => import("@/view/pages/calculator/life/PageView.vue")
        },
        {
          path: "/calculator/travel",
          name: "calculator-travel",
          component: () => import("@/view/pages/calculator/travel/PageView.vue")
        },
        {
          path: "/register-policy",
          name: "register-policy",
          component: () => import("@/view/pages/register-policy/RegisterPolicyPageView.vue")
        },
        {
          path: "/profile/account",
          name: "profile-account",
          component: () => import("@/view/pages/profile/account/ProfileAccountPageView.vue")
        },
        {
          path: "/profile/policies",
          name: "profile-policies",
          component: () => import("@/view/pages/profile/policies/ProfilePoliciesPageView.vue")
        },
        {
          path: "/profile/renewals",
          name: "profile-renewals",
          component: () => import("@/view/pages/profile/renewals/ProfileRenewalsPageView.vue")
        },
        {
          path: "/profile/issues",
          name: "profile-issues",
          component: () => import("@/view/pages/profile/issues/ProfileIssuesPageView.vue")
        },
        {
          path: "/profile/leads",
          name: "profile-leads",
          component: () => import("@/view/pages/profile/leads/ProfileLeadsPageView.vue")
        },
        {
          path: "/profile/settlements",
          name: "profile-settlements",
          component: () => import("@/view/pages/profile/settlements/ProfileSettlementsPageView.vue")
        },
        {
          path: "/profile/calendar",
          name: "profile-calendar",
          component: () => import("@/view/pages/profile/calendar/ProfileCalendarPageView.vue")
        },

        {
          path: "/profile/trainings",
          name: "profile-trainings",
          component: () => import("@/view/pages/profile/trainings/ProfileTrainingsPageView.vue")
        },
        {
          path: "/profile/permissions",
          name: "profile-permissions",
          component: () => import("@/view/pages/profile/permissions/ProfilePermissionsPageView.vue")
        },
        {
          path: "/profile/rodoidd",
          name: "profile-rodoidd",
          component: () => import("@/view/pages/profile/rodoidd/ProfileRodoiddPageView.vue")
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/Login"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/Login")
        }
      ]
    },
    {
      path: "*",
      redirect: "/404",
      component: () => import("@/view/layout/Layout")
    },
    {
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/not-found/PageView")
    }
  ]
});
