import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// consts
export const BROKERS_RESOURCE = "brokers";
export const USERS_RESOURCE = "users";

// action types
export const BROKERS_LIST = "brokersList";
export const USERS_LIST = "userList";
export const USER_ADD = "userAdd";
export const USER_EDIT = "userEdit";

// mutation types
export const SET_BROKERS = "setBrokers";
export const SET_USERS = "setUsers";
export const SET_ERROR = "setError";

export const PURGE_BROKERS = "purgeBrokers";
export const PURGE_USERS = "purgeUsers";

const state = {
  errors: null,
  user: null,
  users: [],
  brokers: []
};

const getters = {
  brokers(state) {
    return state.brokers;
  },
  users(state) {
    return state.users;
  },
  user(state) {
    return state.user;
  }
};

const actions = {
  [BROKERS_LIST](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get(BROKERS_RESOURCE)
        .then(({ data }) => {
          context.commit(SET_BROKERS, data.payload.data);
        })
        .catch(({ response }) => {
          // context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_ERROR, response);
        });
    }
  },
  [USERS_LIST](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get(USERS_RESOURCE)
        .then(({ data }) => {
          context.commit(SET_USERS, data.payload.data);
        })
        .catch(({ response }) => {
          // context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_ERROR, response);
        });
    }
  },
  [USER_EDIT](context, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.update(USERS_RESOURCE, payload.uuid, payload)
        .then(() => {
          let users = context.state.users;
          let newPayload = { ...payload };
          const index = users.findIndex(o => o.uuid === newPayload.uuid);
          if (index !== -1) {
            users.splice(index, 1, newPayload);
            context.commit(SET_USERS, users);
          }
        })
        .catch(({ response }) => {
          // context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_ERROR, response);
        });
    } else {
      context.commit(PURGE_USERS);
    }
  },
  [USER_ADD](context, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post(USERS_RESOURCE, payload)
        .then(({ data }) => {
          let users = context.state.users;
          let newPayload = { ...payload };
          newPayload.uuid = data.payload.data.uuid;
          users.push(newPayload);
          context.commit(SET_USERS, users);
        })
        .catch(({ response }) => {
          // context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_ERROR, response);
        });
    } else {
      context.commit(PURGE_USERS);
    }
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_USERS](state, users) {
    state.users = users;
  },
  [PURGE_USERS](state) {
    state.users = [];
    state.errors = {};
    JwtService.destroyToken();
  },
  [SET_BROKERS](state, brokers) {
    state.brokers = brokers;
  },
  [PURGE_BROKERS](state) {
    state.brokers = [];
    state.errors = {};
    JwtService.destroyToken();
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
